a {
  color: white;
  text-decoration: none;
}

a:hover {
  color: #0099ff;
}

.AbilityDescriptionSection {
  outline-style: dashed;
  outline-width: 4px;
  outline-color: rgb(44, 44, 44);
  background-color: black;
  padding-top: 12px;
  padding-bottom: 12px;
}

.AbilityHeader {
  padding-right: 4px;
  padding-left: 4px;
  color: #0099ff;
  font-size: 130%;
}

.AbilityHeaderSmall {
  text-align: left;
  color: #0099ff;
}

.AbilityCooldown {
  text-align: right;
  padding-right: 4px;
  padding-left: 4px;
  margin-left: 0%;
}

.AccordionButton {
  text-align: left;
  align-self: left;
  font-family: 'Times New Roman', Times, serif;
  font-size: larger;
  margin-left: 10%;
  margin-right: 10%;
  padding: 0.5%;
  margin-bottom: 12px;
  color: white;
}

.AccordionButton:hover {
  color: #0099ff;
}

.alignCenter50 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.animationFadeIn {
  animation-name: fadeIn;
  animation-duration: 1s;
}

.animationFadeIn50pcDelay {
  animation-name: fadeIn50pcDelay;
  animation-duration: 2s;
}

.animationFlyInFromLeft {
  animation-name: flyInFromLeft;
  animation-duration: 1s;
}

.animationFlyInFromRight {
  animation-name: flyInFromRight;
  animation-duration: 1s;
}

.animationRainbowText {
  animation: colorRotate 2s linear 0s infinite;
}

.animationRainbowText:hover {
  animation: none;
}

.animationcolorWhiteOrangeFlashing {
  animation: colorWhiteOrangeFlashing 2s linear 0s infinite;
}

.animationcolorWhiteOrangeFlashing:hover {
  animation: none;
}

.animationLeadershipIconFadeIn {
  animation: treeLeadershipFadeIn 1s;
}

.animationPyromancyIconFadeIn {
  animation: treePyromancyFadeIn 2s;
}

.animationShieldedCombatIconFadeIn {
  animation: treeShieldedCombatFadeIn 3s;
}

.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
}

.auto-resizable-iframe {
  max-width: 50%;
  margin: 0px auto;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.auto-resizable-iframe-learn-more {
  max-width: 100%;
  margin: 0px auto;
}

.auto-resizable-iframe>div {
  position: relative;
  padding-bottom: 75%;
  height: 0px;
}

.auto-resizable-iframe iframe {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.backgroundColorBlack {
  background-color: black;
}

.backgroundRadialBlack {
  background-image: radial-gradient(rgba(22, 22, 22, 0.5), black);
}

.backgroundRadialRed {
  background-image: radial-gradient(rgba(255, 0, 0, 0.5), black);
}

.background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  float: left;
  padding: none;
  overflow: hidden;
  min-height: 100vh;
  min-width: 100vw;
}

.background-video video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  float: left;
  padding: none;
  overflow: hidden;
  min-height: 100vh;
  min-width: 100vw;
}

body {
  background-color: #282c34;
  color: white;
  background-image: url("./resources/images/AbstractCubesBackground.png");
  /* background-size: 100%; */
  word-spacing: normal;
}

.BluePrint {
  background-color: #001133;
  border: #0099ff;
  border-width: 2px;
  border-style: dashed;
  border-collapse: separate;
}

Button {

}

Button:hover {
  background-image: radial-gradient(black, rgb(44, 44, 55));
  color: #0099ff;
}

.CharacterArt {
  width: 20vw;
}

.CogLogo {
  height: 2vw;
  width: 2vw;
  padding-right: 2px;
  padding-top: 4px;
  background-image: radial-gradient(rgb(44, 44, 44), black);
}

.commonButton {
  color: white;
  background-color: green;
  padding: 100px;
}

.cursorClickable {
  cursor: pointer
}

.cursorClickable:hover {
  cursor: pointer;
  outline: 1px solid white;
  background-image: radial-gradient(rgb(44, 44, 44), black);
}

.DesignInsightButton {
  text-align: center;
  align-self: center;
  font-family: 'Times New Roman', Times, serif;
  font-size: 1.5vw;
  background-image: linear-gradient(rgb(11, 11, 11), black);
}

.DesignInsightButton:hover {
  background-image: radial-gradient(rgba(44, 44, 55, 1), black);
  color: #0099ff;
}


.Footer {
  height: 25vh;
  border-top: 40px double rgb(44, 44, 44);
}

.HeaderTitle {
  text-align: center;
  align-self: center;
  font-family: 'Times New Roman', Times, serif;
  font-size: larger;
  font-weight: 700;
  margin-left: 10%;
  margin-right: 10%;
  padding: 0.5%;
  margin-bottom: 12px;
  color: white;
  outline-style: outset;
  outline-width: 6px;
  outline-color: rgb(44, 44, 44);
  background-image: radial-gradient(rgb(11, 11, 11), black);
}

.HeaderTitle:hover {
  color: #0099ff;
  margin-left: 4%;
  margin-right: 4%;
}

.HeaderTitleNoHover {
  text-align: center;
  align-self: center;
  font-size: larger;
  font-weight: bold;
  margin-left: 10%;
  margin-right: 10%;
  padding-left: 0.5%;
  padding-right: 0.5%;
  margin-bottom: 12px;
  color: white;
  outline-style: outset;
  outline-style: ridge;
  outline-width: 6px;
  outline-color: rgb(44, 44, 44);
  background-image: radial-gradient(rgb(11, 11, 11), black);
}

.HeaderTitleWide {
  text-align: center;
  align-self: center;
  font-size: larger;
  font-weight: bold;
  padding-left: 0.5%;
  padding-right: 0.5%;
  margin-bottom: 12px;
  color: white;
  outline-style: outset;
  outline-style: ridge;
  outline-width: 6px;
  outline-color: rgb(44, 44, 44);
  background-image: radial-gradient(rgb(11, 11, 11), black);
}

.HorizontalLineBlue {
  border: 1px solid #0099ff;
}

html {
  scroll-behavior: smooth;
}

html::-webkit-scrollbar {
  width: 10px;
}

html::-webkit-scrollbar-track {
  background-color: rgb(44, 44, 44);
  background-image: linear-gradient(rgb(44, 44, 44), black);
}

html::-webkit-scrollbar-thumb {
  background-image: linear-gradient(white, rgb(99, 99, 99));
  border-radius: 25px;
}

.imageMaxSize100 {
  max-width:100%
}

@keyframes colorWhiteOrangeFlashing {
  from {
    color: white;
  }

  50% {
    color: orange;
  }

  100% {
    color: white;
  }
}

@keyframes colorRotate {
  from {
    color: #6666ff;
  }

  10% {
    color: #0099ff;
  }

  50% {
    color: #00ff00;
  }

  75% {
    color: #ff3399;
  }

  100% {
    color: #6666ff;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  10% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes fadeIn50pcDelay {
  0% {
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes flyInFromLeft {
  0% {
    transform: translateX(-100px);
  }

  10% {
    transform: translateX(-100px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes flyInFromRight {
  0% {
    transform: translateX(100px);
  }

  10% {
    transform: translateX(100px);
  }

  100% {
    transform: translateX(0px);
  }
}

@keyframes growAndShrink {
  0% {
    transform: scale(1);
    color: white;
  }

  25% {
    color: yellow;
  }

  50% {
    transform: scale(1.25);
    color: white;
  }

  75% {
    color: yellow;
  }

  100% {
    transform: scale(1);
    color: white;
  }
}

@keyframes animationImageZoom {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.3);
  }
}

@keyframes leftSlide {
  0% {
    transform: translateX(10vw);
  }

  100% {
    transform: translateX(0vw);
  }
}

@keyframes treeLeadershipFadeIn {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  10% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes treePyromancyFadeIn {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  20% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes treeShieldedCombatFadeIn {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  25% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

.ImageBorder {
  margin-left: 0%;
  margin-right: 0%;
  padding: 0.5%;
  margin-bottom: 12px;
  background-color: black;
  outline-style: outset;
  outline-width: 6px;
  outline-color: rgb(44, 44, 44);
}

.ImageLogo {
  width: 100%;
  outline-style: outset;
  outline-width: 1px;
  outline-color: rgb(44, 44, 44);
  background-image: radial-gradient(rgba(44, 44, 44, 1), black);
}

.ImageLogo:hover {
  width: 100%;
  outline-style: outset;
  outline-width: 3px;
  outline-color: white;
  background-image: radial-gradient(rgba(44, 44, 55, 1), black);
}

.ImageLogoSelected {
  width: 100%;
  outline-style: inset;
  outline-width: 4px;
  outline-color: red;
}

.ImageLogoCard {
  width: 95%;
  outline-style: outset;
  outline-width: 1px;
  outline-color: rgb(44, 44, 44);
  animation-name: fadeIn;
  animation-duration: 2.5s;
}

.ImageLogoCard:hover {
  width: 95%;
  outline-style: outset;
  outline-width: 3px;
  outline-color: white;
}

.ImageDynamicZoom {}

.ImageDynamicZoom:hover {
  animation: animationImageZoom 0.8s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
  animation-direction: alternate;
}

.ImageOverflowWrapper {
  overflow: hidden;
  padding-bottom: -10%;
}

.ImageOverflowWrapper:hover {}

.OverhaulImageLogo {
  width: 65%;
  background-color: rgba(11, 11, 11, 1);
  outline-color: rgba(255, 255, 255, 0.35);
  ;
  outline-width: 2px;
  outline-style: solid;
  padding: 2px;
  margin: 1px;
}

.OverhaulImageLogo:hover {
  width: 90%;
  outline-style: outset;
  outline-width: 6px;
  outline-color: white;
  padding: 2px;
  background-image: radial-gradient(rgb(66, 66, 66), black);
}

.OverhaulImageLogoSelected {
  width: 90%;
  outline-style: inset;
  outline-width: 6px;
  outline-color: red;
  background-image: radial-gradient(rgb(66, 66, 66), black);
  padding: 6px;
}


.MapGeneratorSelectionInput {
  width: 200px;
  background-color: black;
  color: black;
  text-align: center;
  align-self: center;
  margin-left: 45vw;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: 2.25rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.75rem;
}

h5 {
  font-size: 1.5rem;
}

h6 {
  font-size: 1.25rem;
}

p {
  font-size: 1rem;
}

@media (max-width: 480px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 480px) {
  html {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  html {
    font-size: 15px;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1920px) {
  html {
    font-size: 18px;
  }
}

.NavBarSticky {
  position: fixed; 
  top: 0;
  z-index: 1;
  width: 100%;
  overflow: hidden;
  margin: auto;
  min-height: 48px;
}

.NavBarStickyHeader {
  background-color: black;
}

.NavBarSectionPadding {
  padding-top: 200px;
}

.NavBarButton {
  background-image: radial-gradient(rgba(44, 44, 44, 1), black);
  min-height: 48px;
}

.NavBarButton:hover {
  background-image: radial-gradient(rgba(44, 44, 55, 1), black);
}

.NavBarButtonGroup {
    background-image: radial-gradient(rgba(44, 44, 44, 1), black);
}


.NewContentBody {
  background-image: radial-gradient(rgba(44, 44, 44, 0.8), black);
  text-align: left;
}

.PrimaryContentBody {
  /* background-image: radial-gradient(rgba(44, 44, 44, 0.8), black); */
  text-align: left;
}

.NewBanner {
  text-align: left;
  border-bottom: 4px solid #0099ff;
  border-top: 10px groove rgb(66, 66, 66);
  margin-bottom: 2px;
}

.NewBannerImage {
  border: 4px ridge rgb(44, 44, 44);
  align-self: center;
  margin-left: 10%;
  margin-right: 10%;
  animation-name: fadeIn;
  animation-duration: 1s;
  width: 50%;
}

.NewPortraitImage {
  border: 4px ridge rgb(44, 44, 44);
  align-self: left;
  animation-name: fadeIn;
  animation-duration: 1s;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.NewBannerImageCard {
  border: 4px ridge rgb(44, 44, 44);
  align-self: center;
  animation-name: fadeIn;
  animation-duration: 1s;
  width: 75%;
  width: 30vw;

  width: 90%;
  height: 90%;
  overflow: hidden;
  animation-name: fadeIn;
  animation-duration: 2.5s;
  object-fit: cover;
}

.SectionCard {
  background-image: radial-gradient(rgba(44, 44, 44, 1), rgba(22, 22, 22, 1));
  border-color: rgb(44, 44, 44);
  border-radius: 12px;
  width: 90%;
  padding: 2%;
  padding-right: 5%;
  padding-left: 5%;
  animation-name: fadeIn;
  animation-duration: 1.5s;
}

.SectionSubCard {
  background-image: radial-gradient(rgba(44, 44, 44, 0), rgba(22, 22, 22, 0.8));
  border-radius: 10px;
  border: 1px solid #999999;
  padding: 2%;
  padding-right: 5%;
  padding-left: 5%;
  animation-name: fadeIn;
  animation-duration: 1.5s;
}

.ContributionsCardGameDesign {
  text-align: center;
  background-image: radial-gradient(rgb(150, 0, 125), rgb(95, 0, 80));
  border-style:none;
  border-color: rgba(255, 255, 255, 1);
  border-width: 2px;
  border-radius: 8px;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
}

.ContributionsCardArt {
  text-align: center;
  background-image: radial-gradient(rgb(0, 88, 88), rgb(0, 80, 80));
  border-style:none;
  border-color: rgba(255, 255, 255, 1);
  border-width: 2px;
  border-radius: 8px;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
}

.ContributionsCardQA {
  text-align: center;
  background-image: radial-gradient(rgb(124, 0, 0), rgb(120, 0, 0));
  border-style:none;
  border-color: rgba(255, 255, 255, 1);
  border-width: 2px;
  border-radius: 8px;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
}

.ContributionsCardMusic {
  text-align: center;
  background-image: radial-gradient(rgb(66, 124, 0), rgb(62, 120, 0));
  border-style:none;
  border-color: rgba(255, 255, 255, 1);
  border-width: 2px;
  border-radius: 8px;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
}

.ContributionsCardWriting {
  text-align: center;
  background-image: radial-gradient(rgb(44, 44, 154), rgb(40, 40, 150));
  border-style:none;
  border-color: rgba(255, 255, 255, 1);
  border-width: 2px;
  border-radius: 8px;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
}

.ContributionsCardProgramming {
  text-align: center;
  background-image: radial-gradient(rgb(0, 88, 188), rgb(0, 80, 180));
  border-style:none;
  border-color: rgba(255, 255, 255, 1);
  border-width: 2px;
  border-radius: 8px;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
}

.ContributionsCardLeadership {
  text-align: center;
  background-image: radial-gradient(rgb(84, 84, 84), rgb(80, 80, 80));
  border-style:none;
  border-color: rgba(255, 255, 255, 1);
  border-width: 2px;
  border-radius: 8px;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
}

.ContributionsCardProductOwner {
  text-align: center;
  background-image: radial-gradient(rgb(44, 84, 44), rgb(40, 80, 40));
  border-style:none;
  border-color: rgba(255, 255, 255, 1);
  border-width: 2px;
  border-radius: 8px;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
}

.ContributionsCardScrumMaster {
  text-align: center;
  background-image: radial-gradient(rgb(154, 84, 0), rgb(150, 80, 40));
  border-style:none;
  border-color: rgba(255, 255, 255, 1);
  border-width: 2px;
  border-radius: 8px;
  padding: 2px;
  padding-left: 4px;
  padding-right: 4px;
}

.NewBannerImageLearnMore {
  border: 4px ridge rgb(44, 44, 44);
  align-self: center;
  margin-left: 10%;
  margin-right: 10%;
  animation-name: fadeIn;
  animation-duration: 1s;
  width: 80%;
}

.NewBannerLinks {
  text-align: right;
}

.NewBannerLogo {
  width: 50%;
  height: 75%;
  padding-left: 20%;
  align-self: center;
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  margin: 0.5vh;
}

.NewBannerSubtitle {
  font-size: 1rem;
}

.NewBannerText {
  font-size: 2.5rem;
}

.NewNormalPageLayoutLeft {
  text-align: left;
  animation-name: fadeIn;
  animation-duration: 1s;
}

.NormalPageWithLRPadding {
  padding-right: 25px;
  padding-left: 25px;
}

.NormalPageLayout {
  text-align: center;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  animation-name: fadeIn;
  animation-duration: 1s;
}

.NormalPageLayoutWide {
  text-align: left;
  width: 100%;
  animation-name: fadeIn;
  animation-duration: 1s;
}

.NormalPageLayoutLeft {
  text-align: left;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  animation-name: fadeIn;
  animation-duration: 1s;
}

.NormalPageLayoutLeftSmaller {
  text-align: left;
  width: 80%;
  padding-left: 10%;
  padding-right: 10%;
  font-size: small;
}

.NormalPageLayoutSlim {
  text-align: left;
  width: 100%;
  animation-name: fadeIn;
  animation-duration: 1s;
}

.moveLeft5 {
  margin-left: -5%;
}

.moveLeft10 {
  margin-left: -10%;
}

.moveLeft15 {
  margin-left: -15%;
}

.moveLeft20 {
  margin-left: -20%;
}

.moveLeft25 {
  margin-left: -25%;
}

.moveLeft35 {
  margin-left: -35%;
}

.moveLeft45 {
  margin-left: -45%;
}

.moveLeft70 {
  margin-left: -70%;
}

.moveRight0 {
  margin-left: 0%;
}

.moveRight10 {
  margin-left: 10%;
}

.moveRight15 {
  margin-left: 15%;
}

.moveRight25 {
  margin-left: 25%;
}

.outlineBorderOnHover {
}

.outlineBorderOnHover:hover {
  outline-style: outset;
  outline-width: 4px;
  outline-color: white;
  transform: scale(1.01);
  margin-bottom: 4px;
}

.OutlineGray {
  border: rgb(22, 22, 22);
  border-width: 2px;
  border-style: double;
  border-collapse: separate;
}

.OutlineBlue {
  border: #0099ff;
  border-width: 2px;
  border-style: double;
  border-collapse: separate;
}

.ProfileImage {
  margin-right: 10%;
  padding: 0.5%;
  margin-bottom: 12px;
  background-color: black;
  outline-style: outset;
  outline-width: 6px;
  outline-color: rgb(44, 44, 44);
}

.ScrollingBoxDndPreview {
  padding-bottom: 4px;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;
  background-image: radial-gradient(rgb(22, 22, 22), black);
}

.ScrollingBoxDndPreview::-webkit-scrollbar {
  width: 10px;
}

.ScrollingBoxDndPreview::-webkit-scrollbar-track {
  background-color: rgb(44, 44, 44);
  background-image: linear-gradient(rgb(44, 44, 44), black);
}

.ScrollingBoxDndPreview::-webkit-scrollbar-thumb {
  background-image: linear-gradient(white, rgb(99, 99, 99));
  border-radius: 25px;
}

.ScrollingBox {
  height: 30vh;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;
  background-image: radial-gradient(rgb(22, 22, 22), black);
}

.ScrollingBox::-webkit-scrollbar {
  width: 10px;
}

.ScrollingBox::-webkit-scrollbar-track {
  background-color: rgb(44, 44, 44);
  background-image: linear-gradient(rgb(44, 44, 44), black);
}

.ScrollingBox::-webkit-scrollbar-thumb {
  background-image: linear-gradient(white, rgb(99, 99, 99));
  border-radius: 25px;
}

.ScrollingBox50vh {
  height: 65vh;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: left;
}

.SectionHeader {
  text-align: center;
  color: #002255;
  text-shadow: #01F 4px 0 10px;
  -webkit-text-fill-color: #0099ff;
  -webkit-text-stroke: 1px;
}

.SectionHeaderAlignLeft {
  text-align: left;
  color: #002255;
  text-shadow: #01F 4px 0 10px;
  -webkit-text-fill-color: #0099ff;
  -webkit-text-stroke: 1px;
}

.SectionHeaderCard {
  text-align: left;
  color: #0099ff;
  outline-width: 2px;
  outline-color: white;
  padding-left: 4px;
  padding-right: 4px;
}

.SmallHeaderBox {
  text-align: center;
  align-self: center;
  font-size: larger;
  padding-right: 5%;
  padding-left: 5%;
  padding-top: 10%;
  padding-bottom: 10%;
  color: white;
  background-color: rgb(44, 44, 44);
  outline-style: outset;
  outline-width: 6px;
  outline-color: darkgray;
  background-image: linear-gradient(rgb(11, 11, 11), black);
}

.SmallHeaderTitleNoHover {
  text-align: center;
  align-self: center;
  font-size: larger;
  margin-right: 40%;
  margin-bottom: 12px;
  padding-left: 0.5%;
  padding-right: 0.5%;
  padding-top: 5px;
  color: white;
  outline-style: inset;
  outline-width: 6px;
  outline-color: rgb(44, 44, 44);
  background-image: radial-gradient(rgb(11, 11, 11), black);
}

.SmallHeaderTitleNoHoverEvenMargin {
  text-align: center;
  align-self: center;
  font-size: larger;
  margin-bottom: 12px;
  padding-left: 0.5%;
  padding-right: 0.5%;
  padding-top: 5px;
  color: white;
  outline-style: inset;
  outline-width: 6px;
  outline-color: rgb(44, 44, 44);
  background-image: radial-gradient(rgb(11, 11, 11), black);
}

.LevelingGuideTable {
  text-align: center;
  border: 1px solid white;
  background-image: radial-gradient(black, rgb(11, 11, 11));
}

.LevelingGuideTable tr:nth-child(odd) {
  background-color: rgb(44, 44, 44);
}

.SWWBanner {
  width: 25vw;
  offset-anchor: 25vw;
  padding: 10px;
}

.TalentImage {
  width: 10vw;
}

.TreeNametag {
  width: 100%;
  text-align: center;
  align-self: center;

  margin-bottom: 12px;
  padding-top: 5px;
  color: white;
}

.TreeNametagVertical {
  text-align: center;
  align-self: center;
  font-size: larger;
  padding-top: 5px;
  color: white;
  background-color: black;
  outline-style: inset;
  outline-width: 6px;
  outline-color: rgb(44, 44, 44);
  writing-mode: vertical-rl;
  display: inline-block;
}

.textAlignBlock {
  text-align: block;
}

.textAlignCenter {
  text-align: center;
}

.textAlignLeft {
  text-align: left;
}

.textAlignRight {
  text-align: right;
}

.textColorBlue {
  color: #0099ff;
}

.textColorBrown {
  color: brown;
}

.textColorGreen {
  color: green;
}

.textColorIndigo {
  color: rgb(110, 100, 255);
}

.textColorOrange {
  color: orange;
}

.textColorYellow {
  color: yellow;
}

.textColorCyan {
  color: cyan;
}

.textColorRed {
  color: red;
}

.textFontPixel {
  font-family: 'PixelFont';
}

.textFontSizeH1 {
  font-size: 2.5rem;
}

.textFontSizeH2 {
  font-size: 2.25rem;
}

.textFontSizeH6 {
    font-size: 1.25rem;
}

.width35px {
  width: 35px;
}

.width20 {
  width: 20%;
}

.width30 {
  width: 20%;
}

.width40 {
  width: 20%;
}

.width50 {
  width: 50%;
}

.width70 {
  width: 70%;
}

.width75 {
  width: 75%;
}

.width80 {
  width: 80%;
}

.width90 {
  width: 90%;
}

.width95 {
  width: 95%;
}

.width100 {
  width: 100%;
}

.width105 {
  width: 105%;
}

.width200 {
  width: 200%;
}

.height100 {
  height: 20vh;
}